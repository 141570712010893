import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import Buttons from "../layouts/Buttons";
import ReclameAquiWidget from "./ReclameAquiWidget";
import { FaInstagram, FaFacebookSquare } from "react-icons/fa";

import styles from "./css/Footer.module.css";
import fantasy from "../../images/fantasy.png";
import imgPIX from "../../images/pix.png";
import mercadoPago from "../../images/mercado-pago.png";
import Loading from "./Loading";

const Footer = () => {
  const { txt } = useContext(LanguageContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const images = [fantasy, imgPIX, mercadoPago];
    const promises = images.map((image) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image;
        img.onload = () => resolve(img);
        img.onerror = () => reject(new Error(`Failed to load image ${image}`));
      });
    });

    Promise.all(promises)
      .then(() => setLoading(false))
      .catch(() => setError(true)); // Se uma imagem falhar, seta o estado de erro

    return () => {
      // Limpar event listeners ou outras coisas, se necessário
    };
  }, []);

  const socialIcons = (
    <div className={styles.social_networks_icons}>
      <a href="https://www.instagram.com/"><FaInstagram size="30" /></a>
      <a href="https://www.facebook.com/"><FaFacebookSquare size="30" /></a>
    </div>
  );

  const buttonsData = [
    { text: "Termos de uso", action: "terms_of_use" },
    { text: "Políticas de Privacidade", action: "privacy_policy" },
    { text: "Política de Jogo Responsável", action: "responsible_gaming" },
    { text: "Portal da Transparência e Privacidade", action: "transparency_portal" },
    { text: "Dúvidas", action: "faq" },
  ];

  const buttons = buttonsData.map(({ text, action }) => (
    <Buttons
      key={text}
      text={txt(text)}
      action={action}
      layout={["white_link", "size_free"]}
    />
  ));

  const partnerships = (
    <section className={styles.partnerships}>
      <div>
        <h3>{txt("Reputação")}</h3>
        <ReclameAquiWidget />
      </div>
      <div>
        <h3>{txt("Depósito e saque rápido")}</h3>
        <div className={styles.images}>
          <img src={imgPIX} alt="PIX" />
          <img src={mercadoPago} alt="Mercado Pago" />
        </div>
      </div>
    </section>
  );

  if (loading) {
    return <Loading />; 
  }

  return (
    <footer className={styles.footer}>
      <section className={styles.social_networks}>
        <div>
          <img src={fantasy} alt="fantasydraft" />
        </div>
        {socialIcons}
      </section>
      <section className={styles.links}>{buttons}</section>
      {partnerships}
    </footer>
  );
};

export default Footer;
