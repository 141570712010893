import React, { useEffect } from "react";

const ReclameAquiWidget = () => {
  useEffect(() => {
    // Limpa o conteúdo do div container antes de adicionar o script
    const container = document.getElementById("widget-reclame-aqui");
    if (container) {
      container.innerHTML = `
            <div id="reputation-ra"></div>
      `;
    }

    // Cria o script do widget do Reclame Aqui
    const script = document.createElement("script");
    script.id = "ra-embed-reputation";
    script.src = "https://s3.amazonaws.com/raichu-beta/selos/bundle.js";
    script.type = "text/javascript";
    script.setAttribute("data-id", "NXlPWTJGeXA3bS1yZzB5czpmYW50YXN5ZHJhZnQ=");
    script.setAttribute("data-target", "reputation-ra");
    script.setAttribute("data-model", "2");
    script.async = true;

    // Adiciona o script ao div container
    document.getElementById("reputation-ra").appendChild(script);
  }, []);

  return (
    <div id="widget-reclame-aqui">
    </div>
  );
};

export default ReclameAquiWidget;
