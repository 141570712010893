import React, { createContext, useState, useEffect } from 'react';
import Api from '../Api';

const LanguageContext = createContext();

const LanguageProvider = ({children}) => {
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'PT_BR');
    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        const fetchLanguages = async () => {
            try {
                const response = await Api.get('/languages');
                setLanguages(response.data);
            } catch (error) {
            }
        };

        fetchLanguages();
    }, []);

    const switchLanguage = (lang) => {
        setLanguage(lang);
        localStorage.setItem('language', lang);
    };

    const txt = (text) =>{
        /**
         * Componente para logica de futura internacionalização.
         */
        return text;
    }

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
            setLanguage(savedLanguage);
        }
    }, []);

    return(
        <LanguageContext.Provider value={{language, switchLanguage, languages, txt}}>
            {children}
        </LanguageContext.Provider>
    );
}

export {LanguageContext, LanguageProvider};
