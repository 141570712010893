import React, { createContext, useState, useContext, useEffect } from "react";
import Api from "../Api";
import { useNavigate } from "react-router-dom";
import { RoutesContext } from "./RoutesContext";
import Loading from "../components/layouts/Loading";
import { encryptData, decryptData } from "../utils/Cryptography";
import { LanguageContext } from "./LanguageContext";

const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const { txt } = useContext(LanguageContext);
  const { getPathRoute } = useContext(RoutesContext);
  const navigate = useNavigate();

  const scheduleInactivityCheck = (timeToCheck) => {
    const delay = timeToCheck - new Date().getTime();

    if (delay > 0) {
      setTimeout(() => {
        const storedEncryptedTime = localStorage.getItem("loginTime");
        if (storedEncryptedTime) {
          const decryptedTime = decryptData(storedEncryptedTime);
          if (isInactive(decryptedTime)) {
            clearLocalStorage();
            window.location.reload()
          } else {
            // Agendar a próxima verificação para 1 hora após o último login
            const nextCheckTime =
              new Date(decryptedTime).getTime() +1 * 60 * 60 * 1000;
            scheduleInactivityCheck(nextCheckTime);
          }
        }
      }, delay);
    }
  };

  const isInactive = (lastLoginTime) => {
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - new Date(lastLoginTime).getTime();
    return timeDifference >1 * 60 * 60 * 1000;
  };

  const initializeUser = () => {
    const storedEncryptedUser = localStorage.getItem("user");
    const storedEncryptedTime = localStorage.getItem("loginTime");

    if (storedEncryptedUser && storedEncryptedTime) {
      const decryptedUser = decryptData(storedEncryptedUser);
      const decryptedTime = decryptData(storedEncryptedTime);

      if (isInactive(decryptedTime)) {
        clearLocalStorage();
        navigate(getPathRoute("login"));
        return {};
      }

      const currentTime = new Date().toISOString();
      updateLocalStorage(decryptedUser, currentTime);
      scheduleInactivityCheck(
        new Date(currentTime).getTime() +1 * 60 * 60 * 1000
      );

      return { ...decryptedUser, lastLogin: currentTime };
    }

    return {};
  };

  const clearLocalStorage = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("loginTime");
    return true;
  };

  const updateLoginTime = () => {
    const currentTime = new Date().toISOString();
    localStorage.setItem("loginTime", encryptData(currentTime));
  }

  const updateLocalStorage = (user, currentTime) => {
    localStorage.setItem("loginTime", encryptData(currentTime));
    localStorage.setItem("user", encryptData(JSON.stringify(user)));
  };

  const [user, setUser] = useState(initializeUser);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cash, setCash] = useState("0,00");

  useEffect(() => {
    if (user?.id) {
      fetchCash();
    }
  }, [user]);

  const fetchCash = async () => {
    setIsLoading(true);

    try {
      const { data } = await Api.get(`/cash_value/${user.id}`);
      setCash(data.cash);
    } catch (error) {
      setCash(0);
    } finally {
      setIsLoading(false);
    }
  };

  const auth = (user) => {
    const currentTime = new Date().toISOString();
    const encryptedUser = encryptData(JSON.stringify(user));
    const encryptedLoginTime = encryptData(currentTime);
    setUser({ ...user, lastLogin: currentTime });

    localStorage.setItem("user", encryptedUser);
    localStorage.setItem("loginTime", encryptedLoginTime);

    scheduleInactivityCheck(
      new Date(currentTime).getTime() + 1 * 60 * 60 * 1000
    );

    setError("");
  };

  const handleError = (status, defaultMsg = null) => {
    let errorTitle = defaultMsg?.title || "Ocorreu um erro.";
    let errorMessage =
      defaultMsg?.error ||
      "Não se preocupe, estamos resolvendo.<br>Por favor, tente novamente em alguns minutos.";

    if (status === 401) {
      errorTitle = defaultMsg?.title || "O e-mail ou a senha estão incorretos.";
      errorMessage = defaultMsg?.error || "Por favor, tente novamente.";
    }

    const errorHtml = `<h3>${txt(errorTitle)}</h3>${txt(errorMessage)}`;
    setError(errorHtml);
  };

  const login = async (data) => {
    setIsLoading(true);

    try {
      const response = await Api.post("/login", data);
      const { status, data: responseData } = response;
      const { user } = responseData;

      if (status >= 200 && status < 300) {
        auth(user);

        const navigationPath = user.cash === 0 ? "deposit" : "dashboard";
        navigate(getPathRoute(navigationPath));
      }
    } catch (error) {
      handleError(error.response?.status, error.response?.data);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = () => {
    setUser(null);
    ["user", "loginTime"].forEach((item) => localStorage.removeItem(item));
    navigate(getPathRoute("login"));
  };

  const register = async (data) => {
    setIsLoading(true);

    try {
      const response = await Api.post("/register", data);
      const { status, data: responseData } = response;
      const { user, profile } = responseData;
      user.profile = profile;

      if (status >= 200 && status < 300) {
        auth(user);

        navigate(getPathRoute("deposit"));
      }
    } catch (error) {
      handleError(error.response?.status, error.response?.data);
    } finally {
      setIsLoading(false);
    }
  };

  const completeRegister = async (data) => {
    setIsLoading(true);

    try {
      const response = await Api.post("/complete_register", data);
      const { status, data: responseData } = response;

      if (status >= 200 && status < 300) {
        auth(responseData);
        navigate(getPathRoute("deposit"));
      }
    } catch (error) {
      handleError(error.response?.status, error.response?.data);
      navigate(getPathRoute("register"));
    } finally {
      setIsLoading(false);
    }
  };

  const isLogged = () => {
    return !!user?.id ;
  };

  return (
    <UserContext.Provider
      value={{
        error,
        user,
        login,
        logout,
        register,
        completeRegister,
        isLogged,
        auth,
        cash,
        setCash,
        updateLoginTime,
        scheduleInactivityCheck,
      }}
    >
      {isLoading && <Loading />}
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
