import React, { useContext, useState, useEffect, useRef } from "react";
import { LanguageContext } from "../../contexts/LanguageContext";
import Buttons from "../layouts/Buttons";
import { RoutesContext } from "../../contexts/RoutesContext";
import { UserContext } from "../../contexts/UserContext";

import logo from "../../images/logofd.png";
import styles from "./css/Header.module.css";
import Loading from "./Loading";
import { FaBars, FaCoins } from "react-icons/fa";
import Pusher from "pusher-js";

// Custom hook to handle image loading
const useImageLoader = (src) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setLoading(false);
  }, [src]);

  return loading;
};

const Header = () => {
  const { getPathRoute, routes } = useContext(RoutesContext);
  const { txt } = useContext(LanguageContext);
  const { isLogged,cash, user, setCash } = useContext(UserContext);

  const loading = useImageLoader(logo);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

 
  useEffect(() => {
    // Configurar Pusher
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    });

    // Inscrever-se no canal
    const channel = pusher.subscribe(`cash.${user?.id}`);

    
    channel.bind(`updateCash`, function (data) {
      setCash(data.cash)
    });

    
  }, [user?.id, setCash]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  if (loading) {
    return <Loading />;
  }

  const headerButtons = [
    {
      text: txt("Cadastre-se"),
      action: "register",
      layout: ["transparent", "rounded", "upper", "hover_green"],
    },
    {
      text: txt("Jogar Agora"),
      action: "login",
      layout: ["transparent", "rounded", "upper", "hover_green"],
    },
  ];

  const menuItems = routes
    .filter((route) => route.is_menu)
    .sort((a, b) => a.order - b.order);

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <a href={getPathRoute("home")}>
          <img src={logo} alt="Logo FantasyDraft" />
        </a>
      </div>
      <div className={styles.header_content}>
        {isLogged() ? (
          <>
          <div className={styles.money}>
            <div><FaCoins/></div>
            <div>
              <span>{txt("Seu saldo")}</span>
              <span className={styles.money_cash}>R$ {cash}</span>
            </div>
          </div>
            <Buttons
              text={txt("Depositar")}
              action="deposito"
              layout={["transparent", "rounded", "upper", "hover_green"]}
            />
            <div ref={menuRef} className={styles.menu_container}>
              <FaBars className={styles.menu_icon} onClick={toggleMenu} />
              {menuOpen && (
                <ul className={styles.dropdown_menu}>
                  {menuItems.map((item, index) => (
                    <li key={index}>
                      <a href={getPathRoute(item.alias)}>{txt(item.title)}</a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </>
        ) : (
          <div className={styles.header_buttons}>
            {headerButtons.map((button, index) => (
              <Buttons
                key={index}
                text={button.text}
                action={button.action}
                layout={button.layout}
              />
            ))}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
