import CryptoJS from "crypto-js";


// Função para criptografar dados
export const encryptData = (plain_text) => {
    if (!plain_text) return "";
  try {
    const salt = CryptoJS.lib.WordArray.random(256 / 8);
    const iv = CryptoJS.lib.WordArray.random(16);

    const key = CryptoJS.PBKDF2(process.env.REACT_APP_ENCRYPT_KEY, salt, {
      keySize: 256 / 32,
      iterations: 50,
      hasher: CryptoJS.algo.SHA512,
    });

    const encrypted = CryptoJS.AES.encrypt(plain_text, key, { iv: iv });

    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext) + CryptoJS.enc.Hex.stringify(iv) + CryptoJS.enc.Hex.stringify(salt);
  } catch (error) {
    return "";
  }
};

// Função para descriptografar dados
export const decryptData = (data) => {
  if (!data) return "";

  try {
    const payload = data;
    const encrypted = payload.substring(0, payload.length - 96);
    const iv = CryptoJS.enc.Hex.parse(payload.substring(payload.length - 96, payload.length - 64));
    const salt = CryptoJS.enc.Hex.parse(payload.substring(payload.length - 64));

    const key = CryptoJS.PBKDF2(process.env.REACT_APP_ENCRYPT_KEY, salt, {
      keySize: 256 / 32,
      iterations: 50,
      hasher: CryptoJS.algo.SHA512,
    });

    const decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
    const decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);

    try {
      return JSON.parse(decryptedStr);
    } catch (jsonError) {
      return decryptedStr; 
    }
  } catch (error) {
    return "";
  }
};
