import React from "react";
import AppRoutes from "./routes/AppRoutes";
import { UserProvider } from "./contexts/UserContext";
import { LanguageProvider } from "./contexts/LanguageContext";
import { RoutesProvider } from "./contexts/RoutesContext";
import { BrowserRouter as Router } from "react-router-dom";

const App = () => {
  return (
    <Router>
      <LanguageProvider>
        <RoutesProvider>
          <UserProvider>
            <AppRoutes />
          </UserProvider>
        </RoutesProvider>
      </LanguageProvider>
    </Router>
  );
};

export default App;
