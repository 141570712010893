import React, { useState, useEffect } from 'react';
import style from './css/Loading.module.css';
import loading from '../../images/loading.gif';

const Loading = ({longTime = false, transparent = false}) => {
  const [isLongTime, setIsLongTime] = useState(longTime);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLongTime(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`${style.loading} ${transparent ? style.transparent : ""}`}>
      {isLongTime && <img src={loading} alt="loading" />}
    </div>
  );
};

export default Loading;
