import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { RoutesContext } from '../../contexts/RoutesContext';
import styles from './css/Buttons.module.css';

const Buttons = ({ text, layout = 'btn', type = 'link', action = null, icon = null, layoutIcon = null}) => {
  const { getPathRoute } = useContext(RoutesContext);

  const renderButton = () => {
    let classNames = [styles.btn]; // Inicializa com a classe padrão
    let ClassNamesIcon = [];

    // Se layout for um array, adiciona cada classe individualmente
    if (Array.isArray(layout)) {
      classNames = [...classNames, ...layout.map(cls => styles[cls])];
    } else if (typeof layout === 'string') {
      classNames.push(styles[layout]); // Adiciona a classe ao array
    }

    if (Array.isArray(layoutIcon)) {
      ClassNamesIcon = [...ClassNamesIcon, ...layoutIcon.map(cls => styles[cls])];
    } else if (typeof layoutIcon === 'string') {
      ClassNamesIcon.push(styles[layoutIcon]); // Adiciona a classe ao array
    }

    switch (type) {
      case 'button':
        return (
          <button type='button' className={classNames.join(' ')} onClick={typeof action === 'function' ? action : undefined}>
            {icon && (<span className={ClassNamesIcon}>{icon??''}</span>)}{text}
          </button>
        );
      case 'submit':
        return (
          <button type='submit' className={classNames.join(' ')} onClick={typeof action === 'function' ? action : undefined}>
             {icon && (<span className={ClassNamesIcon}>{icon??''}</span>)}{text}
          </button>
        );
      case 'link':
        if (typeof action === 'string') {
          return (
            <a href={getPathRoute(action)} className={classNames.join(' ')}>
              {icon && (<span className={ClassNamesIcon}>{icon}</span>)}
              <span>{text}</span>
            </a>
          );
        }
        return null;
      default:
        return null;
    }
  };

  return <>{renderButton()}</>;
};

Buttons.propTypes = {
  text: PropTypes.string.isRequired,
  layout: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string) // Permite uma string ou um array de strings
  ]),
  type: PropTypes.oneOf(['button', 'link']),
  action: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

export default Buttons;
