import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import Api from "../Api";
import { match } from "path-to-regexp";
import { LanguageContext } from "../contexts/LanguageContext";
import Loading from "../components/layouts/Loading";

const RoutesContext = createContext();

const RoutesProvider = ({ children }) => {
  const { language, txt } = useContext(LanguageContext);
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRoutes = async () => {
      try {
        const response = await Api.get(`/routes`);
        setRoutes(response.data);
      } catch (error) {
      } finally {
        setLoading(false); // Definindo loading como false após a conclusão da chamada da API
      }
    };

    // Antes de fazer a chamada, definimos loading como true
    setLoading(true);
    fetchRoutes(); // Chamada assíncrona, aguardando a resposta antes de prosseguir
  }, [language]);

  const getRoute = useMemo(
    () => (alias) => {
      return routes.find((route) => route.alias === alias);
    },
    [routes]
  );

  const isOnlyLoggedRoute = useMemo(() => {
    const currentPath = window.location.pathname; // Usando window.location.pathname
    for (const route of routes) {
      const matcher = match(route.path, { decode: decodeURIComponent });
      const matched = matcher(currentPath);     
      if (matched) {
        return route.only_logged ?? false; // Retorna true se onlyLogged estiver definido, senão false
      }
    }

    return false; // Se nenhuma rota for correspondente, retorna false
  }, [routes]);

  const getRoutes = useMemo(
    () => () => {
      return routes;
    },
    [routes]
  );

  const getPathRoute = useMemo(
    () =>
      (alias, uuids = {}) => {
        const route = routes.find((route) => route.alias === alias);
        if (route) {
          let path = route.path;
          Object.keys(uuids).forEach((key) => {
            path = path.replace(`:${key}`, uuids[key]);
          });
          return txt(path);
        }
        return alias ?? "#";
      },
    [routes, txt]
  );
  // Retorna o contexto com as rotas e as funções após os dados serem carregados
  return (
    <RoutesContext.Provider
      value={{
        getRoutes,
        getRoute,
        getPathRoute,
        routes,
        loading,
        isOnlyLoggedRoute,
      }}
    >
      {loading === true ? (
        // Renderiza um componente de carregamento enquanto os dados estão sendo carregados
        <Loading />
      ) : (
        // Renderiza os filhos quando os dados estiverem disponíveis
        children
      )}
    </RoutesContext.Provider>
  );
};

export { RoutesContext, RoutesProvider };
